import React, { Component } from 'react'

import PageNotFoundStyled from './styledComponent'
import NotFoundIcon from '../../asset/images/sir-bacon.jpg'

class PageNotFound extends Component {
  renderCenterComponent = () => {
    return (
      <div className="notfound-wrapper">
        <p className="notfound-title">404 Not found</p>
        <div className="img-wrapper">
          <img src={NotFoundIcon} alt="not found icon" />
        </div>
        <p className="notfound-message">Page not found. Oink!</p>
      </div>
    )
  }

  render() {
    return <PageNotFoundStyled>{this.renderCenterComponent()}</PageNotFoundStyled>
  }
}

export default PageNotFound
