import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: ${(props) => props.theme.darkBackground};
  justify-content: center;
  align-items: center;
  .notfound-wrapper {
    width: 610px;
    background-color: white;
    color: ${(props) => props.theme.darkBackground};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 77px;
    .notfound-title {
      font-family: 'Prompt', sans-serif;
      font-weight: bold;
      font-size: 40px;
      margin-bottom: 37px;
      margin-top: 0;
    }
    .img-wrapper {
      width: 235.8px;
      height: 286px;
      margin-bottom: 37px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .notfound-message {
      font-family: 'Prompt', sans-serif;
      font-size: 18px;
      margin-bottom: 0;
    }
  }
`
